// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-agenda-js": () => import("/opt/build/repo/src/templates/agenda.js" /* webpackChunkName: "component---src-templates-agenda-js" */),
  "component---src-templates-default-js": () => import("/opt/build/repo/src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-event-js": () => import("/opt/build/repo/src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-homepage-js": () => import("/opt/build/repo/src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

